<template>
  <v-container>
    <!-- <v-form ref="form" v-model="valid" lazy-validation> -->
    <v-form ref="form" v-model="valid" lazy-validation>
      <v-row>
        <v-col cols="12" md="12">
          <h2>Upload ID Picture</h2>
          <v-divider></v-divider>
          <cropImage />
          
         
          <v-alert
            border="top"
            colored-border
            type="warning"
            elevation="2"
          >
          Please upload picture with <b>white background</b> and <b>white blouse/shirt/polo on top</b>
          </v-alert>
        </v-col>
        <v-col c cols="12" md="12">
          <h2>Personal Info</h2>
          <v-divider></v-divider>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="LastName"
            :counter="45"
            :rules="[vv.init(this.LastName), vv.required(), vv.maxlen(45)]"
            label="Last Name"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="FirstName"
            :rules="[vv.init(this.FirstName), vv.maxlen(45)]"
            label="First Name"
            outlined
            dense
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="MiddleName"
            :counter="45"
            label="Middle Name (Optional)"
            outlined
            dense
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="NameExt"
            type="text"
            :counter="3"
            label="Name Ext. (Optional)"
            outlined
            dense
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-select
            v-model="Gender"
            label="Sex"
            dense
            outlined
            disabled
            :items="genderOpt"
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-select
            v-model="CivilStatus"
            label="Civil Status"
            dense
            outlined
            :items="csOpt"
            disabled
          >
          </v-select>
        </v-col>
        <v-col cols="12" sm="4" md="2">
          <v-checkbox
            v-model="SoloParent"
            label="I am a Solo Parent"
            color="primary"
            hide-details
            disabled
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="4" md="2">
          <v-checkbox
            v-model="TeenageParent"
            label="I am a Teenage Parent"
            color="primary"
            hide-details
            disabled
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="4" md="2">
          <v-checkbox
            v-model="WChildren"
            label="w/ Children"
            color="primary"
            hide-details
            disabled
          ></v-checkbox>
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <v-autocomplete
            v-model="YearConceived"
            :items="yearconcievedOpt"
            item-text="name"
            label="Year Concieved"
            outlined
            dense
            disabled
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="4" md="3">
          <v-autocomplete
            v-model="ReligionID"
            :items="ReligionOpt"
            :filter="customFilter"
            item-text="name"
            label="Religion"
            outlined
            dense
            disabled
          ></v-autocomplete>
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <v-autocomplete
            v-model="ScholarshipID"
            :items="ScholarshipsOpt"
            :filter="customFilter"
            item-text="name"
            label="Scholarship"
            outlined
            dense
            disabled
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="4" md="3">
          <v-autocomplete
            v-model="NationalityID"
            :items="NationalityOpt"
            :filter="customFilter"
            item-text="name"
            label="Nationality"
            outlined
            dense
            disabled
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="4" md="3">
          <v-checkbox
            v-model="Foreigner"
            label="I am a Foreigner"
            color="primary"
            hide-details
            disabled
          ></v-checkbox>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="PassportNo"
            :counter="45"
            label="Passport Number"
            outlined
            dense
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="MobileNo"
            :counter="45"
            label="Mobile Number"
            outlined
            dense
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="Email"
            :counter="45"
            :rules="[vv.init(this.Email), vv.valid_email()]"
            label="Email"
            outlined
            dense
            disabled
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="Guardian"
            label="Guardian"
            outlined
            dense
            :rules="[vv.init(this.Guardian), vv.required(), vv.maxlen(45)]"
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <!-- <v-text-field
            v-model="GuardianContact"
            label="Guardian Contact"
            outlined
            dense
            :rules="[
              vv.init(this.GuardianContact),
              vv.required(),
              vv.maxlen(45),
            ]"
            required
          ></v-text-field> -->
            <v-text-field
              v-model="GuardianContact"
              label="Guardian Contact"
              outlined
              dense
              :rules="[
                vv.init(this.GuardianContact),
                vv.maxlen(45),
              ]"
              disabled
            ></v-text-field>
        </v-col>

        <v-col c cols="12" md="12">
          <h4>Other information</h4>
          <v-divider></v-divider>
        </v-col>

        <!-- INDGENT -->

        <v-col cols="12" sm="6" md="6">
          <v-checkbox
            v-model="Indigent"
            label="I am a member of an Indigenous Community Recognized by the National Commision on Indigenous Peoples"
            color="primary"
            hide-details
          ></v-checkbox>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="IndGroup"
            label="IP Group/Ethnicity"
            outlined
            dense
            required
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="GrossIncome"
            label="Family Annual Gross Income"
            outlined
            dense
            :rules="[
              vv.init(this.GrossIncome),
              vv.required()
            ]"
            required
          ></v-text-field>
        </v-col>


        <!-- DISABLITY -->
          <v-col cols="12" sm="6" md="6">
            <v-checkbox
              v-model="WDisability"
              label="Do  you consider as Person With Disablity(PWD)?"
              color="primary"
              hide-details
              @click="clearDisability"
            ></v-checkbox>
          </v-col>

          <v-col cols="12" sm="6" md="3" v-if="WDisability">
            <v-text-field
              v-model="Disability"
              label="Please specify"
              outlined
              dense
              required
            ></v-text-field>
          </v-col>

        <!-- BIRTH DETAILS -->

        <v-col cols="12" sm="12" md="12">
          <h2>Birth Details</h2>
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-dialog
            ref="dialog"
            v-model="modal"
            :return-value.sync="BithDate"
            persistent
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="BithDate"
                label="Date of Birth"
                prepend-icon="mdi-calendar"
                readonly
                outlined
                dense
                disabled
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="BithDate" scrollable>
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="$refs.dialog.save(BithDate)">
                OK
              </v-btn>
            </v-date-picker>
          </v-dialog>
        </v-col>
        <v-col cols="12" sm="6" md="3">
          <!-- <v-text-field
            v-model="BirthNo"
            :counter="100"
            label="House No./ Block No./ St./Subd/Barangay"
            outlined
            dense
            :rules="[vv.init(this.BirthNo), vv.required(), vv.maxlen(100)]"
            required
          ></v-text-field> -->
            <v-text-field
              v-model="BirthNo"
              :counter="100"
              label="House No./ Block No./ St./Subd/Barangay"
              outlined
              dense
              :rules="[vv.init(this.BirthNo), vv.maxlen(100)]"
              disabled
            ></v-text-field>          
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <v-autocomplete
            v-model="BirthRegionID"
            :items="BirthRegionOpt"
            :filter="customFilter"
            item-text="name"
            label="Region"
            outlined
            dense
            disabled
            @change="setBirthProvince()"
            :rules="[vv.init(this.BirthRegionID)]"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="4" md="3">
          <v-autocomplete
            v-model="BirthProvinceID"
            :items="setBirthProvince()"
            :filter="customFilter"
            item-text="name"
            label="Province"
            outlined
            dense
            disabled
            @change="setBirthCity()"
            :rules="[vv.init(this.BirthProvinceID)]"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="4" md="3">
          <!-- <v-autocomplete
            v-model="BirthMinucipalityID"
            :items="setBirthCity()"
            :filter="customFilter"
            item-text="name"
            label="Municipality"
            outlined
            dense
            :rules="[vv.init(this.BirthMinucipalityID), vv.requiredSelect()]"
          ></v-autocomplete> -->

           <v-autocomplete
              v-model="BirthMinucipalityID"
              :items="setBirthCity()"
              :filter="customFilter"
              item-text="name"
              label="Municipality"
              outlined
              dense
              disabled
              :rules="[vv.init(this.BirthMinucipalityID)]"
            ></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="4" md="3">
          <!-- <v-autocomplete
            v-model="BirthCountryID"
            :items="BirthCountryOpt"
            :filter="customFilter"
            item-text="name"
            label="Country"
            outlined
            dense
            :rules="[vv.init(this.BirthCountryID), vv.requiredSelect()]"
          ></v-autocomplete> -->
          <v-autocomplete
              v-model="BirthCountryID"
              :items="BirthCountryOpt"
              :filter="customFilter"
              item-text="name"
              label="Country"
              outlined
              dense
              disabled
              :rules="[vv.init(this.BirthCountryID)]"
            ></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <h2>Home Address</h2>
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <!-- <v-text-field
            v-model="HomeStreet"
            :counter="100"
            label="House No./ Block No./ St./Subd/Barangay"
            outlined
            dense
            required
            :rules="[vv.init(this.HomeStreet), vv.required(), vv.maxlen(100)]"
          ></v-text-field> -->
            <v-text-field
              v-model="HomeStreet"
              :counter="100"
              label="House No./ Block No./ St./Subd/Barangay"
              outlined
              dense
              disabled
              :rules="[vv.init(this.HomeStreet), vv.maxlen(100)]"
            ></v-text-field>          
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <!-- <v-autocomplete
            v-model="HomeRegionID"
            :items="HomeRegionOpt"
            :filter="customFilter"
            item-text="name"
            label="Region"
            outlined
            dense
            @change="setHomeProvince()"
            :rules="[vv.init(this.HomeRegionID), vv.requiredSelect()]"
          ></v-autocomplete> -->
            <v-autocomplete
              v-model="HomeRegionID"
              :items="HomeRegionOpt"
              :filter="customFilter"
              item-text="name"
              label="Region"
              outlined
              dense
              disabled
              @change="setHomeProvince()"
              :rules="[vv.init(this.HomeRegionID)]"
            ></v-autocomplete>          
        </v-col>

        <v-col cols="12" sm="4" md="3">
          <!-- <v-autocomplete
            v-model="HomeProvinceID"
            :items="setHomeProvince()"
            :filter="customFilter"
            item-text="name"
            label="Province"
            outlined
            dense
            @change="setHomeCity()"
            :rules="[vv.init(this.HomeProvinceID), vv.requiredSelect()]"
          ></v-autocomplete> -->
            <v-autocomplete
              v-model="HomeProvinceID"
              :items="setHomeProvince()"
              :filter="customFilter"
              item-text="name"
              label="Province"
              outlined
              dense
              disabled
              @change="setHomeCity()"
              :rules="[vv.init(this.HomeProvinceID)]"
            ></v-autocomplete>          
        </v-col>

        <v-col cols="12" sm="4" md="3">
          <!-- <v-autocomplete
            v-model="HomeMinicipalityID"
            :items="setHomeCity()"
            :filter="customFilter"
            item-text="name"
            label="Municipality"
            outlined
            dense
            :rules="[vv.init(this.HomeMinicipalityID), vv.requiredSelect()]"
          ></v-autocomplete> -->
            <v-autocomplete
              v-model="HomeMinicipalityID"
              :items="setHomeCity()"
              :filter="customFilter"
              item-text="name"
              label="Municipality"
              outlined
              dense
              disabled
              :rules="[vv.init(this.HomeMinicipalityID)]"
            ></v-autocomplete>          
        </v-col>

        <v-col cols="12" sm="4" md="3">
          <!-- <v-autocomplete
            v-model="HomeCountryID"
            :items="HomeCountryOpt"
            :filter="customFilter"
            item-text="name"
            label="Country"
            outlined
            dense
            :rules="[vv.init(this.HomeCountryID), vv.requiredSelect()]"
          ></v-autocomplete> -->
            <v-autocomplete
              v-model="HomeCountryID"
              :items="HomeCountryOpt"
              :filter="customFilter"
              item-text="name"
              label="Country"
              outlined
              dense
              disabled
              :rules="[vv.init(this.HomeCountryID)]"
            ></v-autocomplete>          
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <h2>Bording Address</h2>
          <v-divider></v-divider>
        </v-col>

        <v-col cols="12" sm="6" md="3">
          <v-text-field
            v-model="BordingStreet"
            :counter="100"
            label="House No./ Block No./ St./Subd/Barangay"
            outlined
            dense
            disabled
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="3">
          <v-autocomplete
            v-model="BordingRegionID"
            :items="BordingRegionOpt"
            :filter="customFilter"
            item-text="name"
            label="Region"
            outlined
            dense
            disabled
            @change="setBordingProvince()"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="4" md="3">
          <v-autocomplete
            v-model="BordingProvinceID"
            :items="setBordingProvince()"
            :filter="customFilter"
            item-text="name"
            label="Province"
            outlined
            dense
            disabled
            @change="setBordingCity()"
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="4" md="3">
          <v-autocomplete
            v-model="BordingMunicipalityID"
            :items="setBordingCity()"
            :filter="customFilter"
            item-text="name"
            label="Municipality"
            outlined
            dense
            disabled
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="4" md="3">
          <v-autocomplete
            v-model="BordingCountryID"
            :items="BordingCountryOpt"
            :filter="customFilter"
            item-text="name"
            label="Country"
            outlined
            dense
            disabled
          ></v-autocomplete>
        </v-col>

        <v-col cols="12" sm="12" md="12">
          <v-btn
            :disabled="!valid"
            color="success"
            class="mr-4 text--white"
            @click="savechanges"
          >
            Save Changes
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      <!-- Your changes have been successfully saved! -->
      {{ snakbar_message }}
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
/* eslint-disable vue/no-unused-components */
import vldn from "@/js/validation";
import cropImage from '../components/CropImage';
export default {
  name: "Personal",
  components: {
    cropImage
  },
  data: () => ({
    snackbar: false,
    snakbar_message: "",
    timeout: 2000,
    valid: true,
    modal: false,
    name: "",
    select: null,
    vv: vldn.methods,
    genderOpt: [
      { value: 1, text: "Male" },
      { value: 2, text: "Female" },
    ],
    csOpt: [
      { value: 1, text: "Single" },
      { value: 2, text: "Married" },
    ],
    ScholarshipsOpt: [],
    ReligionOpt: [],
    NationalityOpt: [],
    yearconcievedOpt: [],
    BirthRegionOpt: [],
    BirthProvinceOpt: [],
    BirthMinucipalityOpt: [],
    BirthCountryOpt: [],

    HomeRegionOpt: [],
    HomeProvinceOpt: [],
    HomeMinicipalityOpt: [],
    HomeCountryOpt: [],

    BordingRegionOpt: [],
    BordingProvinceOpt: [],
    BordingMunicipalityOpt: [],
    BordingCountryOpt: [],

    LastName: "",
    FirstName: "",
    MiddleName: "",
    BithDate: "",
    BirthNo: "",

    BirthRegionID: "",
    BirthProvinceID: "",

    BirthMinucipalityID: "",
    BirthCountryID: "",
    Gender: "",
    ReligionID: "",
    ScholarshipID: "",
    NationalityID: "",
    Foreigner: false,
    PassportNo: "",

    HomeStreet: "",

    HomeRegionID: "",
    HomeProvinceID: "",

    HomeMinicipalityID: "",
    HomeCountryID: "",
    CivilStatus: "",
    MobileNo: "",
    Email: "",
    Guardian: "",
    GuardianContact: "",

    BordingRegionID: "",
    BordingProvinceID: "",

    BordingStreet: "",
    BordingMunicipalityID: "",
    BordingCountryID: "",
    NameExt: "",
    SoloParent: 0,
    TeenageParent: 0,
    WChildren: 0,
    YearConceived: "",
    Indigent: 0,
    IndGroup: "",
    GrossIncome: "",
    WDisability: 0,
    Disability: ""
  }),
  mounted() {
    this.$api.methods.init();
    this.setYearconceived();
    this.getAddresses();
    this.getProfile();
    this.CivilStatus = 1;
    this.Gender = 1;
   
  },
  methods: {

    clearDisability() {
      this.Disability = null;
    },

    savechanges() {
      if (this.$refs.form.validate()) {
        this.$axios({
          method: "post",
          url: process.env.VUE_APP_BASE_URL + "guid/updateprofile",
          headers: {
            Authorization: "Bearer" + localStorage.getItem("usertoken"),
          },
          data: {
            LastName: this.LastName,
            FirstName: this.FirstName,
            MiddleName: this.MiddleName,
            BithDate: this.BithDate,
            BirthNo: this.BirthNo,
            Indigent: this.Indigent,
            IndGroup: this.IndGroup,
            GrossIncome: this.GrossIncome,
            WDisability: this.WDisability,
            Disability: this.Disability,
            BirthMinucipalityID: this.BirthMinucipalityID,
            BirthCountryID: this.BirthCountryID,
            Gender: this.Gender,
            ReligionID: this.ReligionID,
            ScholarshipID: this.ScholarshipID,
            NationalityID: this.NationalityID,
            Foreigner: this.Foreigner,
            PassportNo: this.PassportNo,
            HomeStreet: this.HomeStreet,
            HomeMinicipalityID: this.HomeMinicipalityID,
            HomeCountryID: this.HomeCountryID,
            CivilStatus: this.CivilStatus,
            MobileNo: this.MobileNo,
            Email: this.Email,
            Guardian: this.Guardian,
            GuardianContact: this.GuardianContact,
            BordingStreet: this.BordingStreet,
            BordingMunicipalityID: this.BordingMunicipalityID,
            BordingCountryID: this.BordingCountryID,
            NameExt: this.NameExt,
            SoloParent: this.SoloParent,
            TeenageParent: this.TeenageParent,
            WChildren: this.WChildren,
            YearConceived: this.YearConceived,

          },
        })
          .then((response) => {
            if (response) {
              //console.log(response)
              this.snackbar = true;
              this.snakbar_message = "Your changes have been successfully saved!"
              //
            }
          })
          .catch((error) => {
            console.log(error.response)
            if (error.response) {
              if (error.response.status == 500) {
                //
                this.snackbar = true;
                this.snakbar_message = "Something went wrong. Please try again."
              }
            }
          });
      }
      else{
        this.snackbar = true;
        this.snakbar_message = "Please fill the required fields!"
      }
    },
    setYearconceived() {
      for (var i = new Date().getFullYear(); i >= 1930; i--) {
        this.yearconcievedOpt.push(i);
      }
    },
    getAddresses() {
      this.$axios({
        method: "get",
        url: process.env.VUE_APP_BASE_URL + "guid/addresses",
        headers: {
          Authorization: "Bearer" + localStorage.getItem("usertoken"),
        },
      })
        .then((response) => {
          if (response) {
            this.ScholarshipsOpt = response.data.scholarships;
            this.ReligionOpt = response.data.religions;
            this.NationalityOpt = response.data.nationalities;
            this.BirthRegionOpt = response.data.regions;
            this.BirthProvinceOpt = response.data.provinces;
            this.BirthMinucipalityOpt = response.data.cities;
            this.BirthCountryOpt = response.data.countries;

            this.HomeRegionOpt = response.data.regions;
            this.HomeProvinceOpt = response.data.provinces;
            this.HomeMinicipalityOpt = response.data.cities;
            this.HomeCountryOpt = response.data.countries;

            this.BordingRegionOpt = response.data.regions;
            this.BordingProvinceOpt = response.data.provinces;
            this.BordingMunicipalityOpt = response.data.cities;
            this.BordingCountryOpt = response.data.countries;

            this.BirthRegionID = "4";
            this.BirthProvinceID = "5";
            this.BirthMinucipalityID = "19";
            this.BirthCountryID = "173";

            this.HomeRegionID = "4";
            this.HomeProvinceID = "5";
            this.HomeMinicipalityID = "19";
            this.HomeCountryID = "173";

            // this.getProfile();
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 500) {
              //
            }
          }
        });
    },
    getProfile() {
      this.$axios({
        method: "get",
        url: process.env.VUE_APP_BASE_URL + "guid/profile",
        headers: {
          Authorization: "Bearer" + localStorage.getItem("usertoken"),
        },
      })
        .then((response) => {
          if (response) {
            // if (response.data) {
              console.log(response.data)
              this.LastName = response.data[0].LastName;
              this.FirstName = response.data[0].FirstName;
              this.MiddleName = response.data[0].MiddleName;
              this.BithDate = response.data[0].BithDate;
            this.BirthNo = response.data[0].BirthNo;

            this.Indigent = response.data[0].Indigent;
            this.IndGroup = response.data[0].IndGroup;
            this.GrossIncome = response.data[0].GrossIncome;
            this.WDisability = response.data[0].WDisability;
            this.Disability = response.data[0].Disability;

              var prov = this.BirthMinucipalityOpt.filter(
                (d) => d.value === response.data[0].BirthMinucipalityID
              );
              var provID = prov[0].ProvinceID;
              var reg = this.BirthProvinceOpt.filter((d) => d.value === provID);
              var RegID = reg[0].RegionID;
              this.BirthRegionID = RegID;
              this.BirthProvinceID = provID;
              this.BirthMinucipalityID = response.data[0].BirthMinucipalityID;

              this.BirthCountryID = response.data[0].BirthCountryID;
              this.Gender = response.data[0].Gender;
              this.ReligionID = response.data[0].ReligionID;
              this.ScholarshipID = response.data[0].ScholarshipID;
              this.NationalityID = response.data[0].NationalityID;
              this.Foreigner = response.data[0].Foreigner;
              this.PassportNo = response.data[0].PassportNo;
              this.HomeStreet = response.data[0].HomeStreet;

              prov = this.HomeMinicipalityOpt.filter(
                (d) => d.value === response.data[0].HomeMinicipalityID
              );
              provID = prov[0].ProvinceID;
              reg = this.HomeProvinceOpt.filter((d) => d.value === provID);
              RegID = reg[0].RegionID;
              this.HomeRegionID = RegID;
              this.HomeProvinceID = provID;
              this.HomeMinicipalityID = response.data[0].HomeMinicipalityID;

              this.HomeCountryID = response.data[0].HomeCountryID;
              this.CivilStatus = response.data[0].CivilStatus;
              this.MobileNo = response.data[0].MobileNo;
              this.Email = response.data[0].Email;
              this.Guardian = response.data[0].Guardian;
              this.GuardianContact = response.data[0].GuardianContact;
              this.BordingStreet = response.data[0].BordingStreet;

              prov = this.BordingMunicipalityOpt.filter(
                (d) => d.value === response.data[0].BordingMunicipalityID
              );
              provID = prov[0].ProvinceID;
              reg = this.BordingProvinceOpt.filter((d) => d.value === provID);
              RegID = reg[0].RegionID;

              this.NameExt = response.data[0].NameExt;
              this.SoloParent = response.data[0].SoloParent;
              this.TeenageParent = response.data[0].TeenageParent;
              this.WChildren = response.data[0].WChildren;
              this.YearConceived = response.data[0].YearConceived;


              this.BordingRegionID = RegID;
              this.BordingProvinceID = provID;
              this.BordingMunicipalityID = response.data[0].BordingMunicipalityID;
              this.BordingCountryID = response.data[0].BordingCountryID;
            // }
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 500) {
              //
            }
          }
        });
    },
    customFilter(item, queryText) {
      const textOne = item.name.toLowerCase();
      const textTwo = item.abbr.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
        textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
      );
    },
    setBirthProvince() {
      return this.BirthProvinceOpt.filter(
        (d) => d.RegionID === this.BirthRegionID
      );
    },
    setBirthCity() {
      return this.BirthMinucipalityOpt.filter(
        (d) => d.ProvinceID === this.BirthProvinceID
      );
    },
    setHomeProvince() {
      return this.HomeProvinceOpt.filter(
        (d) => d.RegionID === this.HomeRegionID
      );
    },
    setHomeCity() {
      return this.HomeMinicipalityOpt.filter(
        (d) => d.ProvinceID === this.HomeProvinceID
      );
    },
    setBordingProvince() {
      return this.BordingProvinceOpt.filter(
        (d) => d.RegionID === this.BordingRegionID
      );
    },
    setBordingCity() {
      return this.BordingMunicipalityOpt.filter(
        (d) => d.ProvinceID === this.BordingProvinceID
      );
    },
   
  },
};
</script>
<style>
.container {
  max-width: 80%;
}
</style>
