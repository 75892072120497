<template>
  <v-layout row align-content-space-around xs12 class="pl-3">
    <v-row class="pt-3">
      <v-col cols="12" sm="4" md="3" class="mt-3">
        <img
          :src="cropSrc"
          style="width: 200px; height: auto; border: 1px solid gray"
          alt="Cropped Image"
        />
        <v-file-input
          :rules="rules"
          type="file"
          accept="image/jpeg"
          placeholder="Upload ID Pics"
          label="Upload ID Pics"
          @change="setImage"
        ></v-file-input>
        <div v-show="uploading.p">
        Uploading...
        <v-progress-linear
          indeterminate
          color="green"
        ></v-progress-linear>
        </div>
      </v-col>

      <v-col cols="12" sm="4" md="3" class="mt-3">
        <img
          :src="cropSrc2"
          style="width: 200px; height: auto; border: 1px solid gray"
          alt="Cropped Image"
        />
        <v-file-input
          :rules="rules"
          type="file"
          accept="image/jpeg"
          placeholder="Upload E-Signature"
          label="Upload E-Signature"
          @change="setImageEsignature"
        ></v-file-input>
        <div v-show="uploading.e">
        Uploading...
        <v-progress-linear
          indeterminate
          color="green"
        ></v-progress-linear>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="12">
         <v-alert
            border="top"
            colored-border
            type="info"
            elevation="2"
            max-width= "300px"
            v-if="imageRemarks !== null"
          >
            ID Status: {{ imageRemarks }}
          </v-alert>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialog"
      :max-width="dialogMaxWidth"
      :max-height="dialogMaxHeight"
      :disabled="!imgSrc"
    >
      <v-card>
        <v-card-title> <span class="headline">Crop tool</span> </v-card-title>
        <v-card-text>
          <vue-cropper
            ref="cropper"
            :guides="false"
            :view-mode="0"
             drag-mode="move"
            :cropBoxResizable="false"
            :minCropBoxWidth="300"
            :minCropBoxHeight="360"
            :auto-crop-area="0"
            :minContainerHeight="300"
            :minContainerWidth="360"
            :background="true"
            :rotatable="true"
            :src="imgSrc"
            alt="Source Image"
            :modal="true"
            :img-style="{ width: '300px', height: '360px' }"
            :center="true"
            :highlight="true"
           
            @ready="cropBoxP"
          
          />
        </v-card-text>
        <v-card-actions>
          <!-- <v-tooltip> -->
          <v-btn color="blue darken-1 white--text" @click="cropImage">Save</v-btn>

          <!-- </v-tooltip> -->
          <v-icon color="blue" dark @click="rotate('r')"
            >mdi-rotate-right</v-icon
          >
          <v-icon color="blue" dark @click="rotate('l')"
            >mdi-rotate-left</v-icon
          >
          <v-icon color="blue" dark @click="zoom(0.2)"
            >mdi-magnify-plus-outline</v-icon
          >
          <v-icon color="blue" dark @click="zoom(-0.2)"
            >mdi-magnify-minus-outline</v-icon
          >
          



        
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 white--text" @click="dialog = false">Cancel</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogEsig"
      :max-width="dialogMaxWidth"
      :max-height="dialogMaxHeight"
      :disabled="!imgSrc"
    >
      <v-card>
        <v-card-title> <span class="headline">Crop tool</span> </v-card-title>
        <v-card-text>
          <vue-cropper
            ref="cropperEsig"
            :guides="true"
            :view-mode="0"
             drag-mode="move"
            :cropBoxResizable="false"
            :minCropBoxWidth="360"
            :minCropBoxHeight="300"
            :auto-crop-area="0"
            :minContainerHeight="360"
            :minContainerWidth="300"
            :background="true"
            :rotatable="true"
            :src="imgSrc"
            alt="Source Image"
            :modal="true"
            :img-style="{ width: '360px', height: '300px' }"
            :center="true"
            :highlight="false"
            @ready="cropBoxE"
            
          />
        </v-card-text>
        <v-card-actions>
          <!-- <v-tooltip> -->
          <v-btn color="blue darken-1 white--text" @click="cropImageEsig">Save</v-btn>
          
          <!-- </v-tooltip> -->
          <v-icon color="blue" dark @click="rotateEsig('r')"
            >mdi-rotate-right</v-icon
          >
          <v-icon color="blue" dark @click="rotateEsig('l')"
            >mdi-rotate-left</v-icon
          >

          <v-icon color="blue" dark @click="zoomEsig(0.2)"
            >mdi-magnify-plus-outline</v-icon
          >
          <v-icon color="blue" dark @click="zoomEsig(-0.2)"
            >mdi-magnify-minus-outline</v-icon
          >

          <v-spacer></v-spacer>

          <v-btn color="blue darken-1 white--text" @click="dialogEsig = false"
            >Cancel</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="snackbar.snackbar"
      :timeout="snackbar.timeout"
      shaped
      :color="snackbar.color"
    >
      {{ snackbar.text }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="white"
          text
          v-bind="attrs"
          @click="snackbar.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-layout>
  
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
// import axios from '@/axios.js'

export default {
  props: {
    value: {},
    pk: { default: "image_key" },
    dialogMaxWidth: { default: "600px" },
    dialogMaxHeight: { default: "0.8vh" },
    maxWidth: { default: 1920 },
    maxHeight: { default: 1080 },
    // the URL of the blob image
    objectUrl: { default: "" },
  },
  components: {
    VueCropper,
  },
  data() {
    return {
      
      snackbar: { snackbar: false, text: '', timeout: 2000, color: 'green'},
      uploading: {p: false, e: false},
      profilepic: null,
      imgSrc: "",
      cropImg: null,
      dialog: false,
      file: null,
      filename: null,
      cropBlob: null,

      eSig: null,
      imgSrcEsig: "",
      cropImgEsig: null,
      dialogEsig: false,
      fileEsig: null,
      filenameEsig: null,
      cropBlobEsig: null,
      images: [],
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Image size should be less than 2 MB!",
      ],
      imageRemarks: null,
      ismove: 0,
      
    };
  },
  computed: {
    /*
    croppedFile() {
      return new File([this.cropImg], this.file.name, { type: this.file.type });
    },
    */
    cropSrc() {
      if (this.cropImg == null && this.value == null) {
        if(this.images.profpic){
          return this.images.profpic;
        }
        return "download.png";
      } else {
        return this.cropImg || this.value;
      }
    },
    cropSrc2() {
      if (this.cropImgEsig == null && this.valueEsig == null) {
         if(this.images.esig){
          return this.images.esig;
        }
        return "esig.jpg";
      } else {
        return this.cropImgEsig || this.valueEsig;
      }
    },
  },
  created(){
    this.getStudentPic();
     
  },
  mounted(){
    
  },
  methods: {
    getStudentPic() {
      this.$axios({
        method: "get",
        url: process.env.VUE_APP_BASE_URL + "guid/getStudentPic",
        headers: {
          Authorization: "Bearer" + localStorage.getItem("usertoken"),
        },
        data: {
         
        },
      })
        .then((response) => {
          if (response) {
            this.images = response.data;
            this.imageRemarks = response.data.remarks;
            
            
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 500) {
              //
            }
          }
        });
    },
    cropBoxP(){
      this.$refs.cropper.setCropBoxData({ width: 300, height: 370 });
      
      let x =  document.querySelector(".cropper-modal");
      x.style.opacity = 0;
     
      let element =  document.querySelector(".cropper-view-box");
      let img = document.createElement("img");
      img.src ="cropped.png"
      element.innerHTML = "";
      //if(this.ismove == 0){
        element.prepend(img);
    //  }
      //this.ismove++;
    },
    cropBoxE(){
    
      this.$refs.cropperEsig.setCropBoxData({ width: 360, height: 300 });
      
    },
  
    setImage(e) {
    
     const file = e;
    
      //const file = e.target.files[0];
      // const file = e.files[0];
      // this.file = file
      //this.filename = file.name;

      if (!file.type.includes("image/jpeg")) {
        alert("Please select JPEG file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);
          this.$emit("update:dataUrl", this.imgSrc);
        };

        reader.readAsDataURL(file);
        
        this.dialog = true;
        
        
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    
    setImageEsignature(e) {
      const file = e;
      
      
      //const file = e.target.files[0];
      // const file = e.files[0];
      // this.file = file
    
      this.filenameEsig = file.name;

      if (!file.type.includes("image/")) {
        alert("Please select JPEG file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrcEsig = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropperEsig.replace(event.target.result);
          this.$emit("update:dataUrl", this.imgSrcEsig);
        };

        reader.readAsDataURL(file);
        this.dialogEsig = true;
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    dataURLtoFile(dataurl, filename){
       var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = window.atob(arr[1]), 
            n = bstr.length, 
            u8arr = new Uint8Array(n);
            
        while(n--){
            u8arr[n] = bstr.charCodeAt(n);
        }
       
        return new File([u8arr], filename, {type:mime});
    },
    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
      
// Creating a blob from the cropped image.
      this.$refs.cropper
        .getCroppedCanvas({
          maxWidth: this.maxWidth,
          maxHeight: this.maxHeight,
          fillColor: '#fff'
        })
        .toBlob(
// Converting the image to a blob and then creating an object URL from the blob.
          (blob) => {
            this.cropImg = URL.createObjectURL(blob);
            this.croppedBlob = blob;
            this.$emit("update:objectUrl", this.cropImg);
          },
          "image/jpeg",
          0.95
        );
     
     
      this.dialog = false;
      
// Taking the cropped image and converting it to a dataURL.
      this.profilepic = this.$refs.cropper.getCroppedCanvas({
          fillColor: '#fff'
      }).toDataURL();
      var bodyFormData = new FormData();
      bodyFormData.append('tra', 0);
      bodyFormData.append('StudentIDPic', this.dataURLtoFile(this.profilepic,'prof.jpg'));
      this.uploading.p = true;
      this.$axios({
        method: "post",
        url: process.env.VUE_APP_BASE_URL + "guid/uploadStudentID",
        headers: {       
          Authorization: "Bearer" + localStorage.getItem("usertoken")
        },
        data: bodyFormData
      })
        .then((response) => {
          if (response) {
            //
            this.uploading.p = false;
            this.snackbar.text = "ID Picture uploaded successfully.";
            this.snackbar.color = "green";
            this.snackbar.snackbar = true;
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 500) {
              this.uploading.p = false;
              // this.snackbar.text = "ID Picture is not uploaded. Please try again!";
              this.snackbar.text = "The image is not a valid PNG/JPG file!";
              this.snackbar.color = "red";
              this.snackbar.snackbar = true;
            }
          }
        });
        
    },
    cropImageEsig() {
      // get image data for post processing, e.g. upload or setting image src
      //this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL()
     
     
      this.$refs.cropperEsig
        .getCroppedCanvas({
          maxWidth: this.maxWidth,
          maxHeight: this.maxHeight,
          fillColor: '#fff'
      })
        .toBlob(
          (blob) => {
            this.cropImgEsig = URL.createObjectURL(blob);
            this.croppedBlobEsig = blob;
            this.$emit("update:objectUrl", this.cropImgEsig);

            
          },
          "image/jpeg",
          0.95
        );
      
      this.dialogEsig = false;
      this.eSig = this.$refs.cropperEsig.getCroppedCanvas({
          fillColor: '#fff'
      }).toDataURL();
      var bodyFormData = new FormData();
      bodyFormData.append('tra', 1);
      bodyFormData.append('StudentEsig', this.dataURLtoFile(this.eSig,'esig.jpg'));
      this.uploading.e = true;
      this.$axios({
        method: "post",
        url: process.env.VUE_APP_BASE_URL + "guid/uploadStudentID",
        headers: {
          Authorization: "Bearer" + localStorage.getItem("usertoken"),
        },
        data: bodyFormData
      })
        .then((response) => {
          if (response) {
            this.uploading.e = false;
            this.snackbar.text = "E-Signature uploaded successfully.";
            this.snackbar.color = "green";
            this.snackbar.snackbar = true;
          }
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.status == 500) {
              this.uploading.e = false;
              this.snackbar.text = "E-Signature is not uploaded. Please try again!";
              this.snackbar.color = "red";
              this.snackbar.snackbar = true;
            }
          }
        });
    },
    
    rotate(dir) {
      if (dir === "r") {
        this.$refs.cropper.rotate(90);
      } else {
        this.$refs.cropper.rotate(-90);
      }
    },
  
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
    rotateEsig(dir) {
      if (dir === "r") {
        this.$refs.cropperEsig.rotate(0.1);
      } else {
        this.$refs.cropperEsig.rotate(-0.1);
      }
    },
    zoomEsig(percent) {
      this.$refs.cropperEsig.relativeZoom(percent);
    },
    flip(vert) {
      let { scaleX, scaleY, rotate } = this.$refs.cropper.getData();
      // when image is rotated, direction must be flipped
      if (rotate === 90 || rotate === 270) {
        vert = !vert;
      }
      if (vert) {
        this.$refs.cropper.scale(scaleX, -1 * scaleY);
      } else {
        this.$refs.cropper.scale(-1 * scaleX, scaleY);
      }
    },
    flipEsig(vert) {
      let { scaleX, scaleY, rotate } = this.$refs.cropperEsig.getData();
      // when image is rotated, direction must be flipped
      if (rotate === 90 || rotate === 270) {
        vert = !vert;
      }
      if (vert) {
        this.$refs.cropperEsig.scale(scaleX, -1 * scaleY);
      } else {
        this.$refs.cropperEsig.scale(-1 * scaleX, scaleY);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.v-icon.v-icon.v-icon--link {
  padding: 0 10px;
}


</style>
